@keyframes JoinLetter {
	0% {
		opacity: 0;
		letter-spacing: 1.6rem;
	}

	100% {
		opacity: 1;
		letter-spacing: 0.2rem;
	}
}
@keyframes Rotate {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}
