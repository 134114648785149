@import './base/export';

* {
	@apply box-border;
	font-family: 'Lato', sans-serif;
}

html {
	@apply bg-light;
}

body {
	@apply m-0 p-0 w-full h-full absolute;
	font-family: 'Lato', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	#root {
		@apply min-h-full overflow-auto bg-light;
	}
}

code {
	@apply font-semibold;
	font-family: 'JetBrains Mono', monospace;
}

input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.fa-icon {
	@apply fill-current;
}

a:hover {
	@apply no-underline;
}
