.error-boundary-layout {
	@apply bg-light h-screen flex-center flex-col;
	.fa-icon {
		width: 250px;
		@apply text-gray-600 opacity-20;
	}
	h1 {
		@apply font-bold text-lg mt-4 tracking-widest border-dashed  border-b border-secondary pb-4 border-opacity-10;
	}
	.rs-btn {
		width: 250px;
		@apply mt-5  font-bold shadow-lg bg-secondary text-white transition-all;
	}

	.go-to-home-btn {
		@apply mt-7 bg-secondary;
	}

	.report-btn {
		@apply opacity-60 hover:opacity-100;
	}

	.error-timer {
		width: 250px;
		@apply bg-transparent border border-dashed border-secondary rounded-md text-sm mt-10 flex-center py-2;
	}
}
